<template>
  <div
    class="page-service-category page"
    :class="{ 'empty': !showContentPage && !isLoading }"
  >
    <BreadCrumbs
      v-if="showContentPage"
      class="breadcrumbs"
      :bread-crumbs="breadCrumbsData"
    />
    <section class="service-list-section">
      <SectionTitle
        v-if="showContentPage"
        id="sectionTitle"
        class="service-list-section__title"
        :title="service.parentTypeName"
      />
      <transition name="fade">
        <GroupServiceList
          v-if="showContentPage && !isLoading"
          :service-list="service.list"
          :service-parent-type-id="serviceParentTypeId"
        />
      </transition>
      <ComponentPagination
        v-if="paginationLength > 1"
        class="service-list-pagination"
        :total-page="paginationLength"
        :current-page="activePage"
        @changeActivePageHandler="changeActivePageHandler"
      />
    </section>
    <client-only>
      <ServiceNotAvaliable v-if="!showContentPage && !isLoading" />
    </client-only>
  </div>
</template>

<script>
import ServiceNotAvaliable from '../components/Error/ServiceNotAvaliable.vue'
import SectionTitle from '@/components/ComponentSectionTitle'
import BreadCrumbs from '@/components/ComponentBreadCrumbs.vue'
import GroupServiceList from '@/components/GroupServiceList'
import ComponentPagination from '@/components/ComponentPagination'
const handleFormatService = service => {
  return {
    serviceId: service.ServiceId,
    typeId: service.TypeId || null,
    typeName: service.TypeName || null,
    iconName: service.IconName || null,
    title: service.Title,
    imgUrl: service.imgUri,
    desc: service.Description,
    hasPromotion: service.HasPromotion,
    serviceAddressMode: service.ServiceAddressMode, // 1:地址模式 2:社區模式
    minimumPriceOrPricingMethod: {
      content: service.MinimumPriceOrPricingMethod.Content,
      isNumber: service.MinimumPriceOrPricingMethod.IsNumber
    },
    ratingSummary: {
      totalRatings: service.RatingSummary.TotalRatings,
      averageRating: service.RatingSummary.AverageRating
    }
  }
}
export default {
  components: {
    ServiceNotAvaliable,
    SectionTitle,
    BreadCrumbs,
    GroupServiceList,
    ComponentPagination
  },
  async asyncData ({ route, env, store }) {
    return {
      routeFullPath: route.fullPath,
      webBaseUrl: String(env.BASE_WEB_URL || ' ').slice(0, -1)
    }
  },
  data () {
    return {
      breadCrumbsData: [],
      service: {
        parentTypeName: '',
        parentTypeDescription: '',
        list: []
      },
      serviceData: null,
      activePage: 1,
      paginationLength: 1,
      perPageDataCounts: 12
    }
  },
  async fetch () {
    try {
      if (this.serviceParentTypeId === 'star') {
        this.service.parentTypeName = '推薦服務'
        this.serviceData = await this.$store.dispatch('service/getStarServiceList')
        this.breadCrumbsData = [{ label: '推薦服務', routeName: 'index-id', params: { id: 'star' } }]
      } else {
        const serviceByParentType = await this.$store.dispatch('service/getServicesByParentType', { TypeParent: this.serviceParentTypeId })
        this.serviceData = serviceByParentType.ServiceList.map(handleFormatService)
        this.service.parentTypeName = serviceByParentType.ParentTypeName
        this.service.parentTypeDescription = serviceByParentType.ParentTypeDescription
        this.breadCrumbsData = [{ label: this.service.parentTypeName || '(名稱為空)', routeName: 'index-id', params: { id: this.serviceParentTypeId } }]
      }
      this.service.list = this.serviceData.slice((this.activePage - 1) * this.perPageDataCounts, this.perPageDataCounts)
      this.paginationLength = Math.ceil(this.serviceData.length / this.perPageDataCounts)
    } catch (err) {
      console.log('err', err)
    }
  },
  head () {
    return {
      title: this.service.parentTypeName,
      meta: [
        { hid: 'title', property: 'title', content: this.service.parentTypeName + this.$store.state.meta.titleTemple },
        { hid: 'og:url', property: 'og:url', content: this.webBaseUrl + this.routeFullPath },
        { hid: 'og:title', property: 'og:title', content: this.service.parentTypeName + this.$store.state.meta.titleTemple },
        this.service.parentTypeDescription ? { hid: 'og:description', property: 'og:description', content: this.service.parentTypeDescription } : {},
        { hid: 'twitter:url', property: 'twitter:url', content: this.webBaseUrl + this.routeFullPath },
        { hid: 'twitter:title', property: 'twitter:title', content: this.service.parentTypeName + this.$store.state.meta.titleTemple },
        this.service.parentTypeDescription ? { hid: 'twitter:description', property: 'twitter:description', content: this.service.parentTypeDescription } : {}
      ]
    }
  },
  computed: {
    serviceParentTypeId () {
      return this.$nuxt.$route.params.id
    },
    showContentPage () {
      return this.service.list.length
    },
    isLoading () {
      return this.$fetchState.pending
    }
  },
  watch: {
    async serviceParentTypeId (val) {
      try {
        if (val) {
          this.activePage = 1
          this.$vuetify.goTo('#header', { duration: 300, offset: 0, easing: 'easeInOutQuad' })
          await this.$fetch()
        }
      } catch (err) {
        console.log('err', err)
      }
    }
  },
  methods: {
    async changeActivePageHandler (page) {
      await this.$vuetify.goTo('#header', { duration: 500, offset: 0, easing: 'easeInOutQuad' })
      this.activePage = page
      this.service.list = this.serviceData.slice((page - 1) * this.perPageDataCounts, this.perPageDataCounts * page)
    }
  }
}
</script>

<style lang="scss" scoped>
$contentContainerMaxWidth: calc(263px * 4 + 24px * 3);
$contentContainerMaxWidthMobile: calc(263px * 2 + 24px * 1);
@mixin layoutDefined () {
  max-width: calc(#{$contentContainerMaxWidth} + 10vw);
  margin: 0 auto;
  padding: 0 5vw;
  @media screen and (max-width: $mobileWidth) {
    max-width: calc(#{$contentContainerMaxWidthMobile} + 10vw);
  }
}
.page-service-category {
  @include layoutDefined;
  &.empty {
    display: flex;
    justify-content: center;
    align-content: center;
  }
}
.service-list-section {
  &__title {
    margin-bottom: 32px;
  }
}
.skeleton-card {
  &__container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    &__card {
      $cardMargin: 24px;
      width: calc((100% - #{$cardMargin} * 3) / 4);
      margin-bottom: $cardMargin;
      &:not(:nth-child(4n)) {
        margin-right: $cardMargin;
      }
    }
  }
}
.service-list-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 60px 0;
}
@media screen and (max-width: $mobileWidth) {
  .page-service-category {
    &.empty {
      height: calc(100vh - #{$headerHeightMobile});
      padding-bottom: 40px;
    }
  }
  .breadcrumbs {
    margin: 0 auto;
  }
  .skeleton-card__container__card {
    $cardMargin: 16px;
    width: calc((100% - #{$cardMargin}) / 2);
    margin-bottom: $cardMargin;
    &:not(:nth-child(4n)) {
      margin-right: 0;
    }
    &:not(:nth-child(2n)) {
      margin-right: $cardMargin;
    }
  }
}
</style>
